<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    :extra-actions="extraActions"
    :custom-query="customQuery"
    :allow-duplicate="false"
    :has-insert-btn="false"
    authority="Users_Partner_Connect"
    single-edit
    inline-actions
    hide-edit
    hide-delete
  />
</template>

<script>
// import { getUserRights } from '@/auth/utils'
import EntityList from '@/layouts/entity/EntityList.vue'
import axios from '@/libs/axios'

export default {
  components: {
    EntityList,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    customQuery: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      filterVals: [],
      coverAreaOptions: [],
      categoriesOptions: [],
      categoryListOptions: [],
    }
  },
  computed: {
    columns() {
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'logo', label: this.$i18n.t('logo'), sortable: false },
        { key: 'title', label: this.$i18n.t('name'), sortable: true },
        { key: 'email', label: this.$i18n.t('email'), sortable: false },
        {
          key: 'coverArea.title',
          label: this.$i18n.t('state'),
          sortable: false,
        },
        { key: 'actions', label: this.$i18n.t('actions') },
      ]
    },
    formattedColumns() {
      return [{ key: 'logo', type: 'logo' }]
    },
    extraActions() {
      // const rights = getUserRights()
      return [
        {
          endpoint: 'partners/request',
          icon: 'PenToolIcon',
          title: 'Send Partner Request',
          variant: 'outline-primary',
          // authorityValidation: rights.includes('Products_Product_Publish_Edit'),
        },
        {
          icon: 'EyeIcon',
          title: 'View in e-shop',
          // eslint-disable-next-line prefer-template
          href: process.env.VUE_APP_SHOP_URL + `${this.config.urlEshop}/`,
          appendField: 'eshopSlug',
          variant: 'outline-secondary',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
      ]
    },

    filters() {
      const filterFields = [
        {
          name: 'state',
          label: this.$t('State'),
          options: this.coverAreaOptions,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          maxDateFieldIdx: '2',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          minDateFieldIdx: '1',
          maxDate: new Date(),
        },
      ]
      //to show category filter for Supplier role
      this.config.title.single == this.$i18n.t('Seller') &&
        filterFields.push({
          name: 'categoryId',
          label: this.$t('category'),
          options: this.categoryListOptions,
        })
      return filterFields
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCoverAreas').then((response) => {
      this.coverAreaOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCategories').then((response) => {
      this.categoriesOptions = response.data
    })
    axios.get('categories/list/all-categories').then((response) => {
      this.categoryListOptions = response.data
    })
  },
}
</script>
